@import "../../assets/scss/variable.scss";
.account-page {
  width: 80%;
  height: 80%;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  background: $white;
  display: inline-block;

  .account-header {
    width: 100%;
    height: 50px;
    background: $gray;
    color: $black;
    position: relative;
    left: 0;
    top: 0;
    border-bottom: 2px solid $gray;
    border-top: 1px solid $gray;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: flex-end;
    .page-name {
      text-decoration: underline;
      position: absolute;
      left: 10px;
    }
    .add {
      position: absolute;
      right: 20px;
      padding: 5px 10px 5px 10px;
      background: $dark-gray;
      border: none;
      color: $white;
      cursor: pointer;
      font-weight: 500;
      &:hover {
        background: $yellow;
        color: $black;
      }
    }
  }
  .account-wrapper {
    height: auto;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile-img {
      width: 100px;
      height: 100px;
      background-color: $gray;
      border-radius: 50px;
      margin-bottom: 20px;
    }
  }

  .list-wrapper {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    padding: 10px;
    overflow: scroll;
    .title {
      background: $black;
      color: $white;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 36px;
      font-weight: 600;
    }
    .list-header,
    .list {
      background: $gray;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 36px;
      .col-10 {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .col-20 {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .col-40 {
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .col-60 {
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .col-80 {
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .col-100 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 24px;
        height: 24px;
        &:hover {
          width: 30px;
          height: 30px;
        }
      }

      .email {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .list-header {
      color: $white;
      font-weight: 600;
    }
    .list {
      margin: 0;
      padding: 0;
      &.odd {
        width: 100%;
        background: $gray;
        border-bottom: 1px solid $gray;
      }
      &.even {
        width: 100%;
        background: $light-black;
        border-bottom: 1px solid $light-black;
      }
    }
    .list-row {
      width: 100%;
      background: $gray;
      height: auto;
      display: flex;
      flex-direction: column;
      .collapse-pan {
        width: 100%;
        background: $gray;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 0;
        display: none;
        overflow: hidden;
        &.active {
          display: block;
        }
        /* Style the tab */
        .tab {
          overflow: hidden;
          border: 1px solid $gray;
          background-color: $white;
        }

        /* Style the buttons that are used to open the tab content */
        .tab button {
          background-color: inherit;
          float: left;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 10px 12px;
        }

        /* Change background color of buttons on hover */
        .tab button:hover {
          background-color: $black;
        }

        /* Create an active/current tablink class */
        .tab button.active {
          background-color: $black;
        }

        /* Style the tab content */
        .tabcontent {
          display: none;
          padding: 6px 12px;
          border: 1px solid $gray;
          border-top: none;
          &.active {
            display: block;
          }
        }
      }
    }

    table {
      width: 100%;
      background: $gray;
      th {
        background: $gray;
        font-size: 16px;
        height: 40px;
      }
      tr {
        border-bottom: solid thin;
        height: 40px;
      }
      .large {
        width: 40%;
        & .email {
          &:hover {
            text-decoration: underline;
          }
        }
        img {
          width: 24px;
          height: 24px;
          &:hover {
            width: 30px;
            height: 30px;
          }
        }
      }
      .small {
        width: 10%;
        img {
          width: 24px;
          height: 24px;
          &:hover {
            width: 30px;
            height: 30px;
          }
        }
      }
      .underline {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .subscription-list-container {
    width: 100%;
    height: auto;
    padding: 10px;
    // background-color: #eeeeee;
    position: relative;
    left: 0;
    top: 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    .list-item {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      background-color: $gray;
      .header {
        width: 40%;
        margin-left: 1%;
        padding: 5px;
        font-weight: 600;
        text-align: left;
      }
      .delete-header {
        width: 20%;
        font-weight: 600;
        text-align: center;
      }
      .device-details {
        width: 40%;
        margin-left: 1%;
        padding: 5px;
        text-align: left;
      }
      .delete {
        position: absolute;
        right: 0;
        background-image: url("../../assets/images/delete1.svg");
        background-position: center;
        background-size: 35px 35px;
        background-repeat: no-repeat;
        height: 100%;
        width: 20%;
        cursor: pointer;
      }
    }
    .head {
      margin-bottom: 0;
      background-color: $black;
      color: $white;
      border-bottom: 1px solid $gray;
    }
    .list-item:last-child {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .account-page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: $white;
  }
}
