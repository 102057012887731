@import "../../assets/scss/variable.scss";
.footer-container {
  width: 100%;
  height: auto;
  min-height: 10%;
  max-height: 15%;
  background: #ffd200;
  position: absolute;
  left: 0;
  bottom: 0;

  .social-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .facebook {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      cursor: pointer;
    }
    .twitter {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      cursor: pointer;
    }
    .instagram {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      cursor: pointer;
    }
    .linkedin {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    li {
      color: $black;
      list-style: none;
      padding: 5px 20px 5px 20px;
      margin-right: 20px;
      box-sizing: border-box;
      text-align: center;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .copyright {
    color: $black;
    font-size: 12px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .footer-container {
    width: 100%;
    height: auto;
    min-height: 120px;
    background: $yellow;
    position: relative;
    left: 0;
    top: 0;
  }
}

@media screen and (max-width: 600px) {
  .footer-container {
    min-height: 170px;
    position: relative;
    top: 0;
    left: 0;
  }
}
