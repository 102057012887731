.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        background: #fff;
        color: #000;
        padding: 10px 20px 10px 20px;
        min-width:40%;
        .modal-content {
            background: #fff;
            width: 100%;
            height: auto;
            min-height: 150px;
            padding: 10px 20px 10px 20px;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: 0;
            .file-format{
                font-size: 12px;
                margin-top: -12px;
            }
            .folder-icon{
                height: 100px;
                width: 100px;
            }
            &.small{
              min-height: 100px !important;
            }
            &.otp{
                min-height: 100px !important;
                min-width:35%;
            }
            audio{
                // width: 0;
                // height: 0;
            }
            .preview-content{
                width: 400px;
                height: 250px;
            }
        }
        .center{
            align-items: center;
        }
        .modal-header,
        .modal-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            width: 100%;
            height: auto;
            position: relative;
        }
        .modal-header{
            font-size: 22px;
        }
        .close-btn{
            position: absolute;
            right: 0;
            font-size: 20px;
            color: #000;
            padding: 2px 5px 2px 5px;
            cursor: pointer;
        }

        .text-input{
            padding: 5px 5px 5px 10px;
            margin-bottom: 10px;
        }
        select{
            padding: 5px;
            margin-bottom: 10px;
        }
    }

}