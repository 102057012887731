@import "../../assets/scss/variable.scss";
.schedule-page {
  width: 80%;
  height: 80%;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  background: $white;
  display: inline-block;
  .page-header {
    width: 100%;
    height: 50px;
    background: $gray;
    color: $black;
    position: relative;
    left: 0;
    top: 0;
    border-bottom: 2px solid $gray;
    border-top: 1px solid $gray;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: flex-end;

    .page-name {
      position: absolute;
      left: 10px;
      text-decoration: underline;
      color: $black;
    }

    .add {
      position: absolute;
      right: 20px;
      padding: 5px 10px 5px 5px;
      background: $dark-gray;
      border: none;
      color: $white;
      cursor: pointer;
      font-weight: 500;

      img {
        width: 25px;
        height: 25px;
      }

      &:hover {
        background: $black;
      }
    }
  }

  .schedule-list-container {
    width: 100%;
    height: auto;
    padding: 10px;
    background-color: #eeeeee;
    position: relative;
    left: 0;
    top: 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .list-header {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      background-color: #000;
      color: #fff;
      .header {
        width: 19%;
        margin-left: 1%;
        padding: 5px;
        font-weight: 600;
        text-align: center;
      }
    }
    .list-item {
      width: 100%;
      height: auto;
      // margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #d3d3d3;
      // .delete-header {
      //   position: absolute;
      //   right: 0;
      //   width: 50px;
      //   font-weight: 600;
      //   text-align: center;
      // }
      .list-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .schedule-details {
          // width: 13%;
          // width: 16%;
          width: 19%;
          margin-left: 1%;
          padding: 5px;
          text-align: center;
          &.image {
            text-align: center;
          }
          img {
            height: 50px;
            width: 50px;
          }
          .edit-icon,
          .delete-icon,
          .devices-icon {
            text-align: center;
            height: 30px;
            width: 30px;
            &:hover {
              height: 35px;
              width: 35px;
            }
          }
        }
      }

      .collapse-pan {
        width: 100%;
        background: #eeecec;
        height: auto;
        display: none;
        padding: 0;
        overflow: hidden;
        &.active {
          display: block;
        }
        /* Style the tab */
        .tab {
          overflow: hidden;
          border: 1px solid #ccc;
          background-color: #f1f1f1;
        }

        /* Style the buttons that are used to open the tab content */
        .tab button {
          background-color: inherit;
          float: left;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 10px 12px;
        }

        /* Change background color of buttons on hover */
        .tab button:hover {
          background-color: #ddd;
        }

        /* Create an active/current tablink class */
        .tab button.active {
          background-color: #ccc;
        }

        /* Style the tab content */
        .tabcontent {
          display: none;
          padding: 6px 12px;
          border: 1px solid #ccc;
          border-top: none;
          &.active {
            display: block;
          }
        }
      }

      table {
        width: 100%;
        background: rgb(238, 236, 236);
        th {
          background: gray;
          font-size: 16px;
          height: 40px;
        }
        tr {
          border-bottom: solid thin;
          height: 40px;
        }
        .large {
          width: 40%;
          & .email {
            &:hover {
              text-decoration: underline;
            }
          }
          img {
            width: 24px;
            height: 24px;
            &:hover {
              width: 30px;
              height: 30px;
            }
          }
        }
        .small {
          width: 10%;
          img {
            width: 24px;
            height: 24px;
            &:hover {
              width: 30px;
              height: 30px;
            }
          }
        }
        .underline {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .head {
      margin-bottom: 0;
      background-color: #000;
      color: #fff;
      border-bottom: 1px solid gray;
    }
    .list-item:last-child {
      margin: 0;
    }
  }

  .no-device {
    margin-top: 100px;
    font-size: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .schedule-page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: #ffffff;
  }
}
