@import "../../assets/scss/variable.scss";
.header-container {
  width: 100%;
  height: 70px;
  background: $black;
  color: $white;
  position: relative;
  left: 0;
  top: 0;
  border-bottom: 2px solid $black;

  img {
    position: absolute;
    left: 20px;
    top: 10px;
    height: 50px;
    width: auto;
    cursor: pointer;
  }

  .profile-menu-wrapper {
    width: 150px;
    height: 50px;
    position: absolute;
    right: 20px;
    top: 10px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    .profile-img-wrapper {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        position: absolute;
        top: 0;
        left: 0;
      }

      div {
        position: absolute;
        left: 50px;
        padding: 0 0 0 5px;
        margin: 0;
        width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        font-weight: 500;
      }
    }

    .profile-submenu {
      margin: 0;
      padding: 0;
      z-index: 9999;
      width: auto;
      position: relative;
      top: 0;
      display: block;

      li {
        list-style: none;
        width: 100%;
        padding: 5px;
        background: $gray;
        border: 1px solid $gray;
        cursor: pointer;
        color: $white;
        font-weight: 500;
        &:hover {
          background-color: $yellow;
          border: 1px solid $yellow;
          color: $black;
        }
      }
    }
  }
}
