@import "../../assets/scss/variable.scss";
.static-page {
  width: 79%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  //   top: 72px;
  left: 20.5%;
  background: #ffffff;
}

@media screen and (max-width: 1000px) {
  .static-page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: #ffffff;

    .page-header {
    }
  }
}
