@import "../../assets/scss/variable.scss";
.forget-page {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: $black;
  .header {
    position: relative;
    top: 0;
    left: 0;
    height: 20%;
    width: 100%;
    background: $black;
    border-bottom: 5px solid $yellow;
    .app-logo {
      width: 220px;
      position: absolute;
      left: 19%;
      top: 12%;
    }
    .store-logo {
      position: absolute;
      right: 10%;
      top: 0;
      height: 100%;
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .coming-soon {
        font-size: 28px;
        color: $light-white;
        font-weight: 800;
      }
      .logos {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .store-image {
          width: 45%;
          height: 75;
          cursor: pointer;
        }
      }
    }
  }
  .content {
    position: relative;
    top: 0;
    left: 0;
    height: 70%;
    width: 100%;
    background: gray;
    display: flex;
    flex-direction: row;

    .left-section {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 70%;
      background: url("../../assets/images/login-image.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: $black;
    }

    .right-section {
      position: absolute;
      top: 0;
      left: 70%;
      height: 100%;
      width: 30%;
      background: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        color: $black;
        font-size: 42px;
        text-transform: uppercase;
        margin-top: 20px;
        font-weight: 700;
      }
      .sub-title {
        color: $black;
        font-size: 18px;
        font-weight: 700;
      }
      form {
        width: 100%;
        input[type="text"],
        input[type="password"] {
          width: 80%;
          font-size: 16px;
          outline: none;
          border: none;
          padding: 10px 10px 20px 0;
          box-sizing: border-box;
          background-color: $light-gray;
          color: #000;
          border: 0;
          // margin: 0 0 10px 10%;
          background: $white;
          border-bottom: 2px solid $gray;

          &:focus {
            border-bottom: 2px solid $black;
          }

          &::-webkit-input-placeholder {
            color: $gray;
          }
        }

        .common-error {
          color: $red;
          height: auto;
          text-align: center;
          position: relative;
          left: 0;
          top: 20px;
          display: inline-block;
          width: 80%;
        }

        input[type="button"] {
          width: 80%;
          position: relative;
          top: 20px;
          left: 10%;
          padding: 5px 10px 5px 10px;
          background-color: $yellow;
          color: $black;
          // border-radius: 5px;
          border: 1px solid $black;
          font-size: 14px;
          box-sizing: border-box;
          font-weight: 600;
          display: block;
        }

        .forget {
          width: 80%;
          position: relative;
          top: 25px;
          left: 10%;
          font-size: 16px;
          text-decoration: underline;
          text-align: right;
          cursor: pointer;
          color: $black;
          font-weight: 400;
        }

        .create-account {
          color: gray;
          width: 80%;
          position: relative;
          top: 80px;
          left: 10%;
          font-size: 16px;
          text-align: center;

          span {
            margin-left: 5px;
            text-decoration: underline;
            cursor: pointer;
            color: $black;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .forget-page {
    overflow: scroll;
    height: 130%;
    .header {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 35%;
      background: $black;
      border-bottom: 5px solid $yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .app-logo {
        width: 50%;
        height: 100px;
        position: absolute;
        left: 25%;
        top: 5%;
      }
      .store-logo {
        position: absolute;
        left: 25%;
        top: 110px;
        height: 100px;
        width: 50%;
        // background-color: red;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .coming-soon {
          font-size: 16px;
          color: $light-white;
          font-weight: 800;
        }
        .logos {
          height: 50%;
          width: 100%;
          // background: $light-white;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .store-image {
            width: 45%;
            height: 75;
            cursor: pointer;
          }
        }
      }
    }
    .content {
      position: relative;
      top: 0;
      left: 0;
      height: 70%;
      width: 100%;
      background: $white;
      display: flex;
      flex-direction: column;
      margin-bottom: 30%;
      // overflow-y: scroll;
      .left-section {
        position: absolute;
        top: 0;
        left: 0;
        height: 40%;
        width: 100%;
        background: url("../../assets/images/login-image1.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: $black;
      }

      .right-section {
        position: absolute;
        top: 40%;
        left: 0;
        height: 100%;
        width: 100%;
        background: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        .title {
          color: $black;
          font-size: 42px;
          text-transform: uppercase;
          margin-top: 20px;
          font-weight: 700;
        }
        .sub-title {
          color: $black;
          font-size: 18px;
          font-weight: 700;
        }
        form {
          width: 100%;
          input[type="text"],
          input[type="password"] {
            width: 80%;
            font-size: 16px;
            outline: none;
            border: none;
            padding: 10px 10px 20px 0;
            box-sizing: border-box;
            // background-color: $light-gray;
            color: #000;
            border-bottom: 2px solid $gray;
            background: $white;
            &:focus {
              border-bottom: 2px solid $black;
            }

            &::-webkit-input-placeholder {
              color: $gray;
            }
          }

          .common-error {
            color: $red;
            height: auto;
            text-align: center;
            position: relative;
            left: 0;
            top: 20px;
            display: inline-block;
            width: 80%;
          }

          input[type="button"] {
            width: 80%;
            position: relative;
            top: 20px;
            left: 10%;
            padding: 5px 10px 5px 10px;
            background-color: $yellow;
            color: $black;
            // border-radius: 5px;
            border: 1px solid $black;
            font-size: 14px;
            box-sizing: border-box;
            font-weight: 600;
            display: block;
          }

          .forget {
            width: 80%;
            position: relative;
            top: 25px;
            left: 10%;
            font-size: 16px;
            text-decoration: underline;
            text-align: right;
            cursor: pointer;
            color: $black;
            font-weight: 400;
          }

          .create-account {
            color: gray;
            width: 80%;
            position: relative;
            top: 35px;
            left: 10%;
            font-size: 16px;
            text-align: center;
            margin-bottom: 50px;
            span {
              margin-left: 5px;
              text-decoration: underline;
              cursor: pointer;
              color: $black;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
