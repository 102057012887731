.subscription-success-page {
  width: 79%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  background: #ffffff;
  display: inline-block;
  .message-wrapper{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #008000;
    font-size: 26px;
    font-weight: 500;
  }
}



@media screen and (max-width: 1000px) {
  .subscription-page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: #ffffff;
  }
}