@import "../../assets/scss/variable.scss";
.device-page {
  width: 80%;
  height: 80%;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  background: $white;
  display: inline-block;
  .device-header {
    width: 100%;
    height: 50px;
    background: $gray;
    color: $black;
    position: relative;
    left: 0;
    top: 0;
    border-bottom: 2px solid $gray;
    border-top: 1px solid $gray;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: flex-end;
    .page-name {
      text-decoration: underline;
      position: absolute;
      left: 10px;
    }
    .add {
      position: absolute;
      right: 20px;
      padding: 5px 10px 5px 10px;
      background: $dark-gray;
      border: none;
      color: $white;
      cursor: pointer;
      font-weight: 500;
      &:hover {
        background: $yellow;
        color: $black;
      }
    }
  }

  .device-list-container {
    width: 100%;
    height: 80%;
    padding: 10px;
    background-color: $white;
    position: relative;
    left: 0;
    top: 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .list-item {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .header {
        width: 40%;
        margin-left: 1%;
        padding: 5px;
        font-weight: 600;
        text-align: left;
      }
      .delete-header {
        width: 20%;
        font-weight: 600;
        text-align: center;
      }
      .device-details {
        width: 40%;
        margin-left: 1%;
        padding: 5px;
        text-align: left;
      }
      .delete {
        position: absolute;
        right: 0;
        background-image: url("../../assets/images/delete1.svg");
        background-position: center;
        background-size: 30px 30px;
        background-repeat: no-repeat;
        height: 100%;
        width: 20%;
        cursor: pointer;
        &:hover {
          background-size: 35px 35px;
        }
      }
    }
    .head {
      margin-bottom: 0;
      background-color: $black;
      color: $white;
      border-bottom: 1px solid $gray;
    }
    .list-item:last-child {
      margin: 0;
    }
  }

  .no-device {
    margin-top: 100px;
    font-size: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .device-page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: $white;
  }
}
