@import "../../assets/scss/variable.scss";

.content-page {
  width: 80%;
  height: 80%;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  background: $white;
  display: inline-block;

  .page-header {
    width: 100%;
    height: 50px;
    background: $gray;
    color: $black;
    position: relative;
    left: 0;
    top: 0;
    border-bottom: 2px solid $gray;
    border-top: 1px solid $gray;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: flex-end;

    .page-name {
      position: absolute;
      left: 10px;
      .text-enable,
      .text-disable {
        text-decoration: underline;
        color: $black;
      }
      .text-enable {
        cursor: pointer;
        &:hover {
          color: $white;
        }
      }
    }

    .add {
      position: absolute;
      right: 20px;
      padding: 5px 10px 5px 5px;
      background: $dark-gray;
      border: none;
      color: $white;
      cursor: pointer;
      font-weight: 500;

      img {
        width: 25px;
        height: 25px;
      }

      &:hover {
        background: $black;
      }
    }
  }

  .no-folder {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 32px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 100px;

    img {
      width: 200px;
      height: 200px;
    }
  }

  .model-wrapper {
    position: absolute;
    z-index: 9999;
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.502);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      background: $white;
    }

    .modal_header,
    .modal_footer {
      padding: 1rem 2rem !important;
    }

    .modal_footer {
      text-align: right;

      button {
        margin-left: 0.6rem;
        background: $light-gray;
        border: 1px solid transparent;
        color: $white;
        padding: 0.4rem 0.8rem;
        border-radius: 3px;

        &:first-child {
          background-color: $white;
          border-color: $light-gray;
          color: $light-gray;
        }
      }
    }
  }

  .file-wrapper {
    width: 100%;
    height: 80%;
    padding: 50px 0 50px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    overflow-y: scroll;
    .file {
      background: $white;
      display: flex;
      height: 150px;
      width: 150px;
      box-shadow: 1px 1px 10px 1px $light-gray;
      margin-left: 50px;
      margin-bottom: 50px;
      background-position: 45% 25%;
      background-repeat: no-repeat;
      background-size: 60% 60%;
      position: relative;
      cursor: pointer;

      .icon-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 20%;
        width: 100%;
        display: none;

        img {
          width: 25px;
          height: 25px;
          margin-left: 16px;
        }
      }

      .title {
        position: absolute;
        top: 21%;
        left: 0;
        height: 78%;
        width: 100%;
        background: rgba($color: #000000, $alpha: 0);
        color: $black;
        font-weight: 500;
        font-size: 24px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      &:hover {
        // background-color: rgba($color: $black, $alpha: 0.2);
        background-color: $gray;
        .icon-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }

    .small {
      background-size: 50%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .content-page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: $white;

    .page-header {
    }
  }
}

.react-datetime-picker {
  display: flex;
}
