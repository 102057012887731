@import "../../assets/scss/variable.scss";
.menu-container {
  width: 20%;
  height: 80%;
  background: $black;
  position: relative;
  left: 0;
  top: 0;
  border-right: 2px solid $black;
  float: left;
  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 10px;
      border-bottom: 2px solid transparent;
      color: $white;
      text-align: left;
      font-weight: 600;
      padding-left: 50px;
      cursor: pointer;
      &:hover {
        color: $white;
        background: $light-black;
        border-right: 4px solid $light-black;
      }
      &.active {
        color: $yellow;
        background: $black;
        border-right: 4px solid $gray;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .menu-container {
    width: 100%;
    height: 48px;
    background: $black;
    position: relative;
    left: 0;
    top: 0;
    border-bottom: 2px solid $black;
    display: block;
    ul {
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      li {
        padding: 10px;
        border-bottom: 4px solid transparent;
        &:hover {
          border-right: 0px;
          border-bottom: 4px solid $light-black;
        }
        &.active {
          border-right: 0px;
          border-bottom: 4px solid $gray;
        }
      }
    }
  }
}
