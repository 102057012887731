$red: #ff0000;
$black-50-opacity: #00000080;
$dark-gray: #606062;
$light-gray: #d3d3d3;
$yellow: #ffd200;
$gray: #b6b5b5;
$white: #ffffff;
$light-white: #fefeff;
$black: #000000;
$light-black: #242222;
