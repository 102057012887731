@import "../../assets/scss/variable.scss";
.subscription-page {
  width: 80%;
  height: 80%;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  background: $white;
  display: inline-block;

  .subscription-header {
    width: 100%;
    height: 50px;
    background: $gray;
    color: $black;
    position: relative;
    left: 0;
    top: 0;
    border-bottom: 2px solid $gray;
    border-top: 1px solid $gray;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: flex-end;

    .page-name {
      text-decoration: underline;
      position: absolute;
      left: 10px;
    }

    .create-folder {
      padding: 5px 10px 5px 10px;
      font-size: 16px;
      font-weight: 500;
      outline: none;
      box-shadow: none;
      margin-left: 20px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: $black;
      }
    }
  }
  .card-wrapper {
    width: 100%;
    height: 80%;
    padding: 50px 0 50px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1000px) {
  .subscription-page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: $white;
  }
}
