.reset-page {
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: 0;
  margin: 0;
  // position: relative;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../assets/images/login_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  box-sizing: border-box;

  img {
    height: 80px;
    width: 200px;
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .forget-container {
    width: 80%;
    height: 400px;
    position: absolute;
    left: 10%;
    top: 150px;

    .left-container {
      position: absolute;
      top: 0;
      left: 0%;
      width: 60%;
      height: auto;
      background: rgba(0, 0, 0, 0.2);

      h2 {
        text-align: left;
        padding-left: 20px;
        color: #fff;
        font-size: 30px;
        width: 80%;
      }

      .description {
        position: relative;
        text-align: left;
        padding: 20px;
        color: #fff;
        font-size: 16px;
        font-family: Roboto;
        box-sizing: border-box;
        width: 80%;
        word-break: break-all;
      }
    }

    .right-container,
    .right-container-otp {
      position: absolute;
      top: 0;
      right: 0;
      width: 40%;
      height: auto;
      min-height: 60%;
      background: rgba(0, 0, 0, 0.9);
      margin: 0;

      .input-wrapper {
        position: relative;
        left: 10%;
        top: 20px;
        width: 80%;
        height: auto;
        padding: 10px 0 10px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        //   background-color: gray;
        label {
          color: #fff;
          font-size: 20px;
          font-family: Roboto;
          text-align: left;
          width: 100%;
          margin-bottom: 10px;
        }

        input {
          font-size: 20px;
          outline: none;
          border: none;
          padding: 10px;
          background-color: lightgray;
          color: #000;
          border-radius: 5px;
          border: 0;

          &:focus {
            // border-bottom: 4px solid red;
          }

          &::-webkit-input-placeholder {
            color: gray;
          }
        }

        .error {
          color: red;
          height: 5px;
          text-align: left;
        }
      }

      .common-error {
        color: red;
        height: auto;
        text-align: center;
        position: relative;
        left: 0;
        top: 20px;
        display: inline-block;
        width: 80%;
      }

      button {
        width: 80%;
        position: relative;
        top: 40px;
        left: 10%;
        padding: 10px;
        background-color: red;
        color: #fff;
        border-radius: 5px;
        border: none;
        font-size: 20px;
        box-sizing: border-box;
        font-weight: 600;
        display: block;
      }

      .forget {
        // color: gray;
        width: 80%;
        position: relative;
        top: 50px;
        left: 10%;
        font-size: 16px;
        text-decoration: underline;
        text-align: right;
        cursor: pointer;
        color: #fff;
        font-weight: 400;
      }

      .create-account {
        color: gray;
        width: 80%;
        position: relative;
        top: 80px;
        left: 10%;
        font-size: 16px;
        text-align: center;

        span {
          margin-left: 5px;
          text-decoration: underline;
          cursor: pointer;
          color: #fff;
          font-weight: 400;
        }
      }
    }

    .right-container-otp {
      height: 425px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .reset-page {
    img {
      height: 80px;
      width: 200px;
      position: absolute;
      top: 20px;
      left: 50%;
      margin-left: -100px;
    }

    .forget-container {
      width: 100%;
      height: auto;
      min-height: 100%;
      position: relative;
      top: 120px;
      left: 0;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      overflow: scroll;

      .left-container {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;

        h2 {
          font-size: 24px;
        }
      }

      .right-container {
        width: 90%;
        position: relative;
        top: 0;
        left: 0;
        height: 250px;
      }
    }
  }
}
