@import "../../assets/scss/variable.scss";

.folder-page {
  width: 80%;
  height: 80%;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  background: $white;
  display: inline-block;

  .page-header {
    width: 100%;
    height: 50px;
    background: $gray;
    color: $black;
    position: relative;
    left: 0;
    top: 0;
    border-bottom: 2px solid $gray;
    border-top: 1px solid $gray;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: flex-end;

    .page-name {
      text-decoration: underline;
      position: absolute;
      left: 10px;
    }

    .add {
      position: absolute;
      right: 20px;
      padding: 5px 10px 5px 30px;
      background: $dark-gray;
      border: none;
      color: $white;
      cursor: pointer;
      font-weight: 500;
      background-image: url("../../assets/images/add.svg");
      background-size: 25px 25px;
      background-position: 3px 5px;
      background-repeat: no-repeat;

      &:hover {
        background: $yellow;
        color: $black;
        background-image: url("../../assets/images/add_active.svg");
        background-size: 25px 25px;
        background-position: 3px 5px;
        background-repeat: no-repeat;
      }
    }
  }

  .no-folder {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 32px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 100px;

    img {
      width: 200px;
      height: 200px;
    }
  }

  .model-wrapper {
    position: absolute;
    z-index: 9999;
    display: block;
    height: 100%;
    width: 100%;
    background: $black-50-opacity;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      background: $white;
    }

    .modal_header,
    .modal_footer {
      padding: 1rem 2rem !important;
    }

    .modal_footer {
      text-align: right;

      button {
        margin-left: 0.6rem;
        background: $light-gray;
        border: 1px solid transparent;
        color: $white;
        padding: 0.4rem 0.8rem;
        border-radius: 3px;

        &:first-child {
          background-color: $white;
          border-color: $light-gray;
          color: $light-gray;
        }
      }
    }
  }

  .folder-wrapper {
    width: 100%;
    height: 80%;
    padding: 50px 0 50px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    overflow-y: scroll;
    .folder {
      background: $white;
      display: flex;
      height: 200px;
      box-shadow: 1px 1px 10px 1px $light-gray;
      width: 200px;
      margin-left: 50px;
      margin-bottom: 50px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80% 80%;
      position: relative;
      // background-color: rgba($color: #000000, $alpha: 0.4);
      cursor: pointer;

      .icon-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 20%;
        width: 100%;
        // background: #000;
        display: none;

        img {
          width: 35px;
          height: 35px;
          margin-left: 20px;
        }
      }

      .title {
        position: absolute;
        top: 21%;
        left: 0;
        height: 78%;
        width: 100%;
        background: rgba($color: $black, $alpha: 0);
        display: block;
        color: #000;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      &:hover {
        background-color: $gray;

        .icon-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .folder-page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: $white;
    display: inline-block;
  }
}

.btn-primary {
  color: $white;
  background-color: $dark-gray !important;
  border-color: $dark-gray !important;
  &:hover {
    background-color: $black !important;
    border-color: $black !important;
  }
}
