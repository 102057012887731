@import "../../assets/scss/variable.scss";
.card-wrapper {
  width: 100%;
  height: auto;
  padding: 50px 0 50px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;

  .card {
    background: $white;
    display: flex;
    // padding: 50px;
    box-shadow: 1px 1px 10px 1px $light-gray;
    width: 22%;
    margin-left: 50px;
    margin-bottom: 30px;
    h4 {
      padding: 20px;
      // background: linear-gradient(
      //   to right,
      //   #ff9600,
      //   #ff8405,
      //   #ff7111,
      //   #ff5c1b,
      //   #ff4325,
      //   #ff3334,
      //   #ff2141,
      //   #ff004d,
      //   #ff0060,
      //   #ff0074,
      //   #ff0087,
      //   #ff009b
      // );
      background: $white;
    }
    .details-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 20px;
      justify-content: center;
      .price {
        color: $black;
        font-family: GothamBlack-Regular;
        font-size: 22px;
      }
      .time {
        color: $black;
        font-family: GothamBlack-Regular;
        font-size: 22px;
      }
      .devices {
        color: $black;
        font-family: GothamBlack-Regular;
        font-size: 16px;
      }
    }
    .subscribe-btn {
      width: 100%;
      padding: 10px;
      background: $dark-gray;
      font-size: 24px;
      font-weight: 500;
      color: $white;
      border: none;
      &:hover {
        background: $black;
        color: $white;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .card-wrapper {
    .card {
      background: $white;
      // padding: 20px;
      display: flex;
      // padding: 10px;
      box-shadow: 1px 1px 10px 1px $light-gray;
      width: 25%;
      margin-left: 50px;
      h4 {
        padding: 20px;
        background: $white;
        // background: linear-gradient(
        //   to right,
        //   #ff9600,
        //   #ff8405,
        //   #ff7111,
        //   #ff5c1b,
        //   #ff4325,
        //   #ff3334,
        //   #ff2141,
        //   #ff004d,
        //   #ff0060,
        //   #ff0074,
        //   #ff0087,
        //   #ff009b
        // );
      }
      .details-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 20px;

        span {
          display: inline-block;
          width: 50%;
          text-align: center;
        }
      }
    }
  }
}
